import React from 'react'
import './Hero.css'
import '../../src/App.css'

import Header from '../Headers/Header'
import hero_image from "../assets/hero_image.png"
import hero_image_back from "../assets/hero_image_back.png"
import heart from "../assets/heart.png"
import calories from "../assets/calories.png"
const Hero = () => {
  return (
    <div className="hero">
        <div className="blur hero-blur">
            
        </div>
        <div className="Left-h">
            <Header/>
            <div className="the-best-ad">
                <div></div>
                <span>the best fitness club in this town</span>
            </div>

            {/* hero headinh */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape</span>
                    <span> Your</span>
                </div>
                <div><span>Ideal body</span></div>
                <div>
                    <span>
                        In here we will help you to shape and build you ideal body and live upp your life to fullest
                    </span>
                </div>
            </div>
             
            {/* figures */}
            <div className="figures">
                <div>
                    <span>+140</span>
                    <span>expert coaches</span>
                </div>
                <div>
                    <span>+978</span>
                    <span>Members joined</span>
                </div>
                <div>
                    <span>+50</span>
                    <span>Fitness programs</span>
                </div>
            </div>

            {/* hero buttom */}

            <div className="hero-buttons">
                <buttons className="btn">Get Started</buttons>
                <buttons className="btn">Learn More</buttons>
            </div>





        </div>
        <div className="Right-h">
            <button className="btn">Join Now</button>
            <div className="heart-rate">
                <img src={heart} alt="" />
                <span>Heart rate</span><span>116 bpm</span>

            </div>

            <img src={hero_image} alt="" className='hero-image'/>
            <img src={hero_image_back} alt="" className='hero-image-back'/>

            <div className="calories">
                <img src={calories} alt="" />
                <div>
                   <span>Calories Burned</span>
                   <span>220 kcal</span>
                </div>
            
            </div>
        </div>
    </div>
  )
}

export default Hero
