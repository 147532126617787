import React from 'react'
import './program.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Program = () => {
  return (
    <div>
       <div className="program" id="programs">
        <div className="programs-header">
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text'>to shape you</span>
        </div>
       </div>

       <div className="program-catgories">
        {programsData.map((item)=>{
        return(
          <div className="category">
            {item.image}
            <span>{item.heading}</span>
            <span>{item.details}</span>
            <div className="join-now"><span>Join now</span><img src={RightArrow} alt="" /></div>
          </div>
        )
        })}
       </div>

    </div>




  )
}

export default Program
