import React from 'react'
import {plansData} from '../../data/plansData.js'
import whiteTick from '../../assets/whiteTick.png'
import './Plans.css'
const Plans = () => {
  return (
   <div className="plans-container">
    <div className="programs-header" style={{gap: '2rem', marginLeft:"7rem"}}>
        <span className='stroke-text'>READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className='stroke-text'>NOW WITHUS</span>
    </div>

    {/* plans card */}

    <div className="plans">
      {plansData.map((plansCard,i)=>{
        return(
       <>          
        <div className="plan" key={i}>
            {plansCard.icon}
            <span>{plansCard.name}</span>
           <span>$ {plansCard.price}</span>

           <div className="features">
            {plansCard.features.map((feature,i)=>{
                return(
                    <div className="feature">
                        <img src={whiteTick} alt="" />
                        <span key={i}>{feature}</span>
                    </div>
                )
            })}
         
        </div>
         <div>
            <span>See more Benefits -</span>
           
          </div>
          <button className="btn">Join now</button>
        </div>

        

     
        </>
        )        
      })}
    </div>
   </div>
  )
}

export default Plans
