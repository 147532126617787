import './App.css';
import Hero from './components/Hero';
import Program from './components/programs/Program';
import Reason from './components/Reasons/Reason';
import Plans from './components/Plans/Plans';
import Join from './components/join/Join';
import Testimonial from './components/Testimonials/Testimonial';
import Footer from './components/footer/Footer';
function App() {
  return (
    <div className="App">
        <Hero/>
        <Program/>
        <Reason/> 
        <Plans/>
        <Testimonial/>
        <Join/>
        <Footer/>
    </div>
  );
}

export default App;
