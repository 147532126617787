import React, { useState } from 'react'
import './Testimonila.css'
import {testimonialsData} from "../../data/testimonialsData"
import leftArrow from "../../assets/leftArrow.png"
import rightArrow from "../../assets/rightArrow.png"


const Testimonial = () => {
  const [selected,setSelected] = useState(0)
  const testmonialLength = testimonialsData.length;


  return (
    <div className="testimonial">
          <div className="left-t">
            <span>Testimonial</span>
            <span className='stroke-text'>What they</span>
            <span>say about us</span>
            <span>
                {testimonialsData[selected].review}
            </span>
            <span>
                <span style={{color:'var(--orange)'}}>
                    {testimonialsData[selected].name}
                </span>
                - {testimonialsData[selected].status}
            </span>
          </div>
          <div className="right-t">
            <div></div>
            <div></div>
            <img src={testimonialsData[selected].image} alt="" />
            <div className="arrows">
                <img src={leftArrow} alt="" onClick={()=>{
                    selected === 0? setSelected(testmonialLength-1):setSelected((prev)=> prev -1)
                }}/>
                <img src={rightArrow} alt="" onClick={()=>{
                    selected === testmonialLength-1 ? setSelected(0):setSelected((prev)=> prev+1)
                }} />
            </div>
          </div>
    </div>
    
   
    
  )
}

export default Testimonial
